"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "公司名称",
  dataIndex: "companyName",
  key: "companyName"
}, {
  title: "客户订单号",
  dataIndex: "orderNo",
  key: "orderNo"
}, {
  title: "任务名称",
  dataIndex: "taskName",
  key: "taskName"
}, {
  title: "自由职业者",
  dataIndex: "userName",
  key: "userName"
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone"
}, {
  title: "收款账号",
  dataIndex: "bankNo",
  key: "bankNo"
}, {
  title: "交易金额",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "卡密",
  dataIndex: "secret",
  key: "secret"
}, {
  title: "交易状态",
  dataIndex: "payStatus",
  scopedSlots: {
    customRender: "payStatus"
  }
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark"
}];
var _default = {
  data: function data() {
    return {
      data: [],
      columns: columns
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.selectPayRecordByOrderOn)({
      orderNo: this.$route.query.orderNo
    }).then(function (res) {
      _this.data = res.data;
    });
  },
  methods: {
    exportExcel: function exportExcel() {
      window.open("/youhuo/api/v1/freelancePayRecords/export?orderNo=" + this.$route.query.orderNo);
    },
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;