"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

var _settings = _interopRequireDefault(require("@/settings"));

var _request = _interopRequireDefault(require("@/utils/request"));

function getSetting() {
  return (0, _request.default)({
    url: "/user/api/v1/systemConfigs/domain",
    method: "get"
  });
}

var copyright_title = _settings.default.copyright_title,
    system_title = _settings.default.system_title,
    need_subject = _settings.default.need_subject,
    subject_title = _settings.default.subject_title,
    need_project = _settings.default.need_project,
    project_title = _settings.default.project_title,
    fixed_header = _settings.default.fixed_header,
    need_logo = _settings.default.need_logo,
    need_tab = _settings.default.need_tab,
    login_wx = _settings.default.login_wx,
    use_site_name = _settings.default.use_site_name;
var state = {
  copyright_title: copyright_title,
  system_title: system_title,
  need_subject: need_subject,
  subject_title: subject_title,
  need_project: need_project,
  project_title: project_title,
  fixed_header: fixed_header,
  need_logo: need_logo,
  need_tab: need_tab,
  login_wx: login_wx,
  use_site_name: use_site_name,
  deviceType: "web",
  login_code: true,
  login_pwd: true,
  wechatDomain: process.env.VUE_APP_HOST,
  loaded: false,
  systemBackground: ""
};
var mutations = {
  updateSetting: function updateSetting(state, data) {
    state.login_wx = data.wechatLoginEnable;
    state.copyright_title = data.copyRight || state.copyright_title;
    state.deviceType = data.deviceType || "web";
    state.login_code = data.verificationLoginEnable;
    state.login_pwd = data.passwordLoginEnable;
    state.wechatDomain = data.wechatDomain || process.env.VUE_APP_HOST;
    state.loaded = true;
    state.systemBackground = data.systemBackground;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref) {
    var commit = _ref.commit;
    return getSetting().then(function (res) {
      var data = res.data;

      if (data.webTitle) {
        document.title = data.webTitle;
      }

      if (data.webIcon) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = data.webIcon;
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      commit("updateSetting", data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
      return Promise.reject(false);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;