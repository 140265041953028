"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.concat.js");

var _menu = _interopRequireDefault(require("../router/menu"));

var use_local_menus = true;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  mainHeight: function mainHeight(state) {
    return state.app.mainHeight;
  },
  token: function token(state) {
    return state.auth.token;
  },
  propertyId: function propertyId(state) {
    return state.auth.propertyId;
  },
  avatar: function avatar(state) {
    return state.auth.avatar;
  },
  name: function name(state) {
    return state.auth.name;
  },
  userInfo: function userInfo(state) {
    return state.auth.userInfo;
  },
  projectId: function projectId(state) {
    return state.project.projectId;
  },
  permissions: function permissions(state) {
    return state.auth.permissions;
  },
  permissions_menu: function permissions_menu(state) {
    return use_local_menus ? _menu.default : state.auth.permissions && state.auth.permissions.menuList ? state.auth.permissions.menuList : [];
  },
  permissions_menu_tree: function permissions_menu_tree(state) {
    return use_local_menus ? toTree(_menu.default) : state.auth.permissions && state.auth.permissions.menuList ? toTree(state.auth.permissions.menuList) : [];
  },
  permissions_handle: function permissions_handle(state) {
    return state.auth.permissions && state.auth.permissions.permissionStringList ? state.auth.permissions.permissionStringList.filter(function (handle) {
      return handle.includes("hr:");
    }) : [];
  },
  permissions_site: function permissions_site(state) {
    return state.auth.permissions && state.auth.permissions.siteList ? state.auth.permissions.siteList : [];
  },
  tabs: function tabs(state) {
    return state.navtab.value;
  },
  current_tab: function current_tab(state) {
    return state.navtab.current_tab;
  }
};
var _default = getters;
exports.default = _default;

function toTree(data) {
  data.forEach(function (item) {
    delete item.children;
  });
  var map = {};
  data.forEach(function (item) {
    map[item.id] = item;
  });
  var val = [];
  data.forEach(function (item) {
    var parent = map[item.parentId];

    if (!!parent) {
      (parent.children || (parent.children = [])).push(Object.assign(item, {
        title: item.name,
        key: "".concat(item.id, "&&").concat(item.name, "&&").concat(item.isDirectory)
      }));
    } else {
      val.push(Object.assign(item, {
        title: item.name,
        key: "".concat(item.id, "&&").concat(item.name, "&&").concat(item.isDirectory)
      }));
    }
  });
  return val;
}