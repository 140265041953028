"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = exports.default = void 0;

require("core-js/modules/es.function.name.js");

var _xqjrModuleAuth = require("xqjr-module-auth");

var _tool = require("@/utils/tool");

var _nprogress = _interopRequireDefault(require("nprogress/nprogress"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _routeConfigs = _interopRequireDefault(require("./routeConfigs"));

_vue.default.use(_vueRouter.default);

_nprogress.default.configure({
  showSpinner: false
});

var createRouter = function createRouter() {
  return new _vueRouter.default({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: _routeConfigs.default
  });
};

var router = createRouter();
var white_list = ["/auth/login", "/auth/forget", "/404", "/home", "/intro", "/about"]; // 访问前用户状态判断

router.beforeEach(function (to, from, next) {
  if (from.name !== null) {
    _nprogress.default.start();
  }

  if (white_list.indexOf(to.path) > -1) {
    next();
  } else {
    var token = (0, _xqjrModuleAuth.getToken)();

    if (!!token) {
      if (to.path === "/auth/login") {
        next({
          path: "/"
        });
      } else {
        next();
      }
    } else {
      next("/auth/login?redirect=".concat(to.path));
    }
  }
});
router.afterEach(function () {
  _nprogress.default.done();
});

var resetRouter = function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
};

exports.resetRouter = resetRouter;
var _default = router;
exports.default = _default;