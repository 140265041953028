"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReq = createReq;
exports.customerBalance = customerBalance;
exports.deleteReq = deleteReq;
exports.deleteReqs = deleteReqs;
exports.detailReq = detailReq;
exports.freelancePayRecordSave = freelancePayRecordSave;
exports.listAll = listAll;
exports.retrieveReq = retrieveReq;
exports.selectPayRecordByOrderOn = selectPayRecordByOrderOn;
exports.updateFreelancePayRecordByOrderNo = updateFreelancePayRecordByOrderNo;
exports.updatePayStatusCancelByOrderNo = updatePayStatusCancelByOrderNo;
exports.updatePayStatusExamineByOrderNo = updatePayStatusExamineByOrderNo;
exports.updateReq = updateReq;
exports.uploadFile = uploadFile;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Description: 上游客户订单
 * @Author: june
 */
// 新增
function createReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders",
    method: "post",
    data: data
  });
} // 修改


function updateReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders",
    method: "put",
    data: data
  });
} // 单个删除


function deleteReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders/".concat(params),
    method: "delete"
  });
} // 批量删除


function deleteReqs(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders",
    method: "delete",
    data: data
  });
} // 根据id查找


function detailReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders/".concat(data),
    method: "get"
  });
} // 查找所有


function listAll(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders/listAll",
    method: "get",
    params: params
  });
} // 查看导入详情


function selectPayRecordByOrderOn(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelancePayRecords/selectPayRecordByOrderOn",
    method: "get",
    params: params
  });
} // 分页查询


function retrieveReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders",
    method: "get",
    params: params
  });
}

function freelancePayRecordSave(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelancePayRecords/freelancePayRecordSave",
    method: "post",
    data: data
  });
}

function uploadFile(data) {
  return (0, _request.default)({
    url: "/file/api/v1/general/upload/attachment",
    method: "post",
    data: data
  });
}

function updatePayStatusCancelByOrderNo(orderNo) {
  return (0, _request.default)({
    url: "/youhuo/api//v1/freelancePayRecords/updatePayStatusCancelByOrderNo",
    method: "put",
    data: {
      orderNo: orderNo
    }
  });
}

function updatePayStatusExamineByOrderNo(orderNo) {
  return (0, _request.default)({
    url: "/youhuo/api//v1/freelancePayRecords/updatePayStatusExamineByOrderNo",
    method: "put",
    data: {
      orderNo: orderNo
    }
  });
}

function updateFreelancePayRecordByOrderNo(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelancePayRecords/updateFreelancePayRecordByOrderNo",
    method: "put",
    data: data
  });
} // 根据订单查询金额 余额


function customerBalance(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerOrders/balanceByOrderNo/".concat(data),
    method: "get"
  });
}