"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _YHHeader = _interopRequireDefault(require("./YHHeader"));

var _divider = _interopRequireDefault(require("./divider"));

var _YHBottom = _interopRequireDefault(require("./YHBottom"));

var _YHFooter = _interopRequireDefault(require("./YHFooter"));

var _YHCard = _interopRequireDefault(require("./YHCard2"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  components: {
    YHHeader: _YHHeader.default,
    Divider: _divider.default,
    YHBottom: _YHBottom.default,
    YHFooter: _YHFooter.default,
    YHCard2: _YHCard.default
  },
  props: {},
  data: function data() {
    return {
      tab: "chuantong",
      cards: [{
        title: "网红直播",
        content: "（直播收入结算）",
        img: require("./images/intro/zhibo.png")
      }, {
        title: "微商&社交电商",
        content: "（范立返佣金）",
        img: require("./images/intro/weishang.png")
      }, {
        title: "在线医疗",
        content: "（线上医生结算）",
        img: require("./images/intro/yiliao.png")
      }, {
        title: "在线教育",
        content: "（代理结算）",
        img: require("./images/intro/jiaoyu.png")
      }, {
        title: "物流快递",
        content: "（司机&快递员结算）",
        img: require("./images/intro/wuliu.png")
      }, {
        title: "外卖",
        content: "（骑手费用结算）",
        img: require("./images/intro/waimai.png")
      }, {
        title: "物业保洁",
        content: "（工作人员薪资结算）",
        img: require("./images/intro/wuye.png")
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;