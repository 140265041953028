import { render, staticRenderFns } from "./SubMenu.vue?vue&type=template&id=290dc886&"
import script from "./SubMenu.vue?vue&type=script&lang=js&"
export * from "./SubMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/test-e-youhuo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('290dc886')) {
      api.createRecord('290dc886', component.options)
    } else {
      api.reload('290dc886', component.options)
    }
    module.hot.accept("./SubMenu.vue?vue&type=template&id=290dc886&", function () {
      api.rerender('290dc886', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/sidebar/components/SubMenu.vue"
export default component.exports