"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.find-index.js");

require("core-js/modules/es.array.splice.js");

var state = {
  value: [{
    title: "总揽",
    url: "/dashboard"
  }],
  current_tab: "/dashboard"
};
var mutations = {
  setCurrentTab: function setCurrentTab(state, key) {
    state.current_tab = key;
  },
  addTab: function addTab(state, payload) {
    var res = state.value.find(function (item) {
      return item.url === payload.url;
    });

    if (!res) {
      state.value.push(payload);
    }
  },
  removeTab: function removeTab(state, key) {
    var idx = state.value.findIndex(function (item) {
      return item.url === key;
    });

    if (idx > -1) {
      state.value.splice(idx, 1);
    }

    state.current_tab = state.value[state.value.length - 1]["url"];
  },
  initTabs: function initTabs(state) {
    state.value = [{
      title: "总揽",
      url: "/dashboard"
    }];
    state.current_tab = "/dashboard";
  },
  removeAllTab: function removeAllTab(state) {
    if (state.value.length > 1) {
      state.value.splice(1, state.value.length - 1);
      state.current_tab = state.value[state.value.length - 1]["url"];
    }
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations
};
exports.default = _default;