"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AppMain",
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["tabs", "current_tab"])), (0, _vuex.mapState)(["settings"])), {}, {
    key: function key() {
      return this.$route.path;
    }
  }),
  watch: {
    current_tab: function current_tab(val) {
      if (val !== this.$route.path) {
        this.$router.push(val);
      }
    }
  },
  created: function created() {
    if (!this.tabs) {
      this.$store.commit("navtab/initTabs");
      location.reload();
    }
  },
  mounted: function mounted() {
    try {
      var height = document.getElementById("main-content").offsetHeight;
      this.$store.commit("app/updateMainHeight", height - 40);
    } catch (err) {
      console.log("debug log --> ", err);
    }
  },
  methods: {
    removeTab: function removeTab(key) {
      this.$store.commit("navtab/removeTab", key);
    },
    setCurrentTab: function setCurrentTab(key) {
      if (this.$route.path !== key) {
        this.$router.push(key);
        this.$store.commit("navtab/setCurrentTab", key);
      }
    },
    removeAllTab: function removeAllTab() {
      this.$store.commit("navtab/removeAllTab");
    }
  }
};
exports.default = _default;