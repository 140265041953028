"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.array.find-index.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var _layout = _interopRequireDefault(require("../layout"));

var _menu = _interopRequireDefault(require("./menu"));

console.log(_menu.default);
var menuComponentMap = {// "/config/user": () => import("xqjr-module-config/views/user"),
  // "/config/role": () => import("xqjr-module-config/views/role")
};
var AuthRouter = {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("xqjr-module-auth/layout/index.vue"));
    });
  },
  children: [{
    path: "/",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("xqjr-module-auth/layout/wrapper.vue"));
      });
    },
    children: [{
      path: "login",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("xqjr-module-auth/views/login.vue"));
        });
      }
    }, {
      path: "forget",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("xqjr-module-auth/views/forget.vue"));
        });
      }
    }]
  }]
};
var menuTree = buildMenuTree(_menu.default);
var otherRoutes = [{
  parent: "provide",
  path: "customerOrder/upload",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/provide/customerOrder/upload"));
    });
  }
}, {
  parent: "provide",
  path: "customerOrder/grant",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/provide/customerOrder/grant"));
    });
  }
}, {
  parent: "provide",
  path: "customerOrder/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/provide/customerOrder/detail"));
    });
  }
}, {
  parent: "contract",
  path: "upload",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/basic/freelance/upload"));
    });
  }
}, {
  parent: "contract",
  path: "detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/basic/freelance/detail"));
    });
  }
}, {
  parent: "provide",
  path: "customerOrder/order",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/provide/customerOrder/order"));
    });
  }
}, {
  parent: "task/customerTask",
  path: "upload",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/task/customerTask/upload"));
    });
  }
}, {
  parent: "task",
  path: "freelanceTask/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/task/freelanceTask/detail"));
    });
  }
}, {
  parent: "task",
  path: "customerTask/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("../views/task/customerTask/detail"));
    });
  }
}];
menuTree = buildRoutes(otherRoutes, menuTree);

var _default = menuTree.concat([AuthRouter, {
  path: "/",
  redirect: "dashboard"
}, {
  path: "/tools",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/layout/tools"));
    });
  },
  children: [{
    path: "form",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tools/form"));
      });
    }
  }]
}, {
  path: "/home",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/home"));
    });
  }
}, {
  path: "/intro",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/home/intro"));
    });
  }
}, {
  path: "/about",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/home/about"));
    });
  }
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/404"));
    });
  }
} // {
//   path: "*",
//   redirect: "/404",
//   hidden: true
// }
]);

exports.default = _default;

function buildMenuTree(data) {
  var tree = transMenuArrToTree(data, 0);
  return tree;
}

function transMenuArrToTree(data, parentId) {
  var result = [];
  var temp = null;

  var _loop = function _loop(i) {
    if (data[i].parentId === parentId) {
      var obj = null;

      if (parentId === 0) {
        // 如果存在次级菜单
        if (data.filter(function (it) {
          return it.parentId === data[i]["id"];
        }).length > 0) {
          obj = Object.assign({}, data[i], {
            path: data[i]["url"],
            component: _layout.default
          });
        } else {
          obj = Object.assign({}, data[i], {
            path: data[i]["url"],
            component: _layout.default,
            children: [{
              path: "",
              url: data[i]["url"] + "/",
              component: menuComponentMap[data[i]["url"]] ? menuComponentMap[data[i]["url"]] : function (resolve) {
                return require(["@/views".concat(data[i]["url"])], resolve);
              }
            }]
          });
        }
      } else {
        obj = Object.assign({}, data[i], {
          path: data[i]["url"],
          component: menuComponentMap[data[i]["url"]] ? menuComponentMap[data[i]["url"]] : function (resolve) {
            return require(["@/views".concat(data[i]["url"])], resolve);
          }
        });
      }

      temp = transMenuArrToTree(data, data[i].id);

      if (temp.length > 0) {
        obj.children = temp;
      }

      result.push(obj);
    }
  };

  for (var i = 0; i < data.length; i++) {
    _loop(i);
  }

  return result;
}

function buildRoutes(routes, routeTree) {
  if (!routes || routes.length < 1) {
    return routeTree;
  }

  routes.map(function (item) {
    findParent(item, routeTree);
  });
  return routeTree;
}

function findParent(route, routeTree) {
  var paths = routeTree.map(function (item) {
    return item.url.split("/").slice(1).join("/");
  });
  var idx = paths.findIndex(function (p) {
    return route.parent === p;
  });

  if (idx > -1) {
    if (!routeTree[idx]["children"]) {
      routeTree[idx]["children"] = [];
    }

    routeTree[idx]["children"].push(Object.assign({
      url: routeTree[idx]["url"] + route.url
    }, route));
  } else {
    var hazyIdx = paths.findIndex(function (p) {
      return route.parent.split("/").includes(p.split("/")[0]);
    });

    if (hazyIdx > -1) {
      if (!routeTree[hazyIdx]["children"]) {
        routeTree[hazyIdx]["children"] = [];
      }

      findParent(route, routeTree[hazyIdx]["children"]);
    }
  }
}