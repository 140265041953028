var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-page" },
    [
      _c(
        "div",
        { staticClass: "nav-box" },
        [
          _c("a-icon", { attrs: { type: "left" }, on: { click: _vm.goBack } }),
          _vm._v("薪酬发放列表 ")
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "mb24 mt24",
          attrs: { type: "primary" },
          on: { click: _vm.exportExcel }
        },
        [_vm._v(" 导出 ")]
      ),
      _c("div", { staticClass: "mb24" }, [
        _vm._v(
          " 合计：" +
            _vm._s(
              (_vm.data && _vm.data.length > 0 && _vm.data[0].amountCount) || 0
            ) +
            " "
        )
      ]),
      _c("a-table", {
        attrs: { columns: _vm.columns, "data-source": _vm.data },
        scopedSlots: _vm._u([
          {
            key: "payStatus",
            fn: function(text) {
              return _c("span", {}, [
                text === 1 ? _c("span", [_vm._v("待支付")]) : _vm._e(),
                text === 2 ? _c("span", [_vm._v("已支付")]) : _vm._e(),
                text === 3 ? _c("span", [_vm._v("已取消")]) : _vm._e(),
                text === 4 ? _c("span", [_vm._v("支付中")]) : _vm._e(),
                text === 5 ? _c("span", [_vm._v("支付失败")]) : _vm._e(),
                text === 6 ? _c("span", [_vm._v("待审核")]) : _vm._e(),
                text === 7 ? _c("span", [_vm._v("审核不通过")]) : _vm._e()
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }