"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.concat.js");

var _xqjrModuleAuth = require("xqjr-module-auth");

var _api = require("../../basic/customer/api");

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "手机号",
  dataIndex: "phone"
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "原因",
  dataIndex: "reason"
}];
var _default = {
  data: function data() {
    return {
      id: "",
      columns: columns,
      data: {},
      fileList: [],
      uploading: false,
      selectedRowKeys: [],
      headers: {},
      Faildata: [],
      file: "",
      list: [],
      selectCustomer: "",
      dialogVisible: false,
      dialogFailVisible: false
    };
  },
  created: function created() {
    this.headers = {
      token: (0, _xqjrModuleAuth.getToken)(),
      "Content-Type": "multipart/form-data"
    };
    this.id = this.$route.query.id;
  },
  methods: {
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    handleUpload: function handleUpload() {
      var _this = this;

      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        (0, _axios.default)({
          url: "/youhuo/api/v1/freelanceTasks/import?taskId=".concat(this.id, "&force=false"),
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this.handleSuccess(res.data);

          _this.uploading = false;
        });
      } else {
        this.$message.warning("没有文件");
      }
    },
    onSelectChange: function onSelectChange() {},
    handleTableChange: function handleTableChange() {},
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.reset();
    },
    handleSuccess: function handleSuccess(res) {
      var _this2 = this;

      var that = this;

      if (res.success && res.data !== null) {
        var numAll = res.data.importFailList.length + res.data.importSucList.length;
        var numFail = res.data.importFailList.length;
        var numSuc = res.data.importSucList.length;
        this.$confirm({
          okText: "确定",
          cancelText: "查看未成功名单",
          title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
          onOk: function onOk() {
            that.goback();
          },
          onCancel: function onCancel() {
            that.Faildata = res.data.importFailList;
            that.dialogFailVisible = true;
          }
        });
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          cancelText: "取消",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this2.reset();
        }, 2000);
      }
    },
    goback: function goback() {
      var path = "/task/customerTask";
      this.$router.push({
        path: path
      });
    },
    download: function download() {
      window.open( // "https://res.lanxiang.co/test/c5b8dabf-f08d-4d72-9957-ecce9276ee31/31638520-1f6a-4064-b029-6eaf2bf895e5.xlsx"
      "/youhuo/api/v1/excel/template/download?templateName=freelanceTaskImportTemplate.xlsx");
    },
    reset: function reset() {
      this.fileList = [];
    },
    lookRole: function lookRole() {
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;