"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _xqjrModuleAuth = require("xqjr-module-auth");

var _api = require("../freelancePayRecord/api");

var _api2 = require("./api");

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "手机号",
  dataIndex: "phone",
  key: "phone"
}, {
  title: "姓名",
  dataIndex: "userName",
  key: "userName"
}, {
  title: "金额",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark"
}];
var _default = {
  data: function data() {
    return {
      columns: columns,
      orderNo: "",
      data: {},
      amountCount: "",
      balanceCount: "",
      successList: [],
      uploading: false
    };
  },
  created: function created() {
    var _this = this;

    this.orderNo = this.$route.query.orderNo; // 余额
    // 价格

    (0, _api2.customerBalance)(this.orderNo).then(function (res) {
      _this.amountCount = res.data.amount;
      _this.balanceCount = res.data.balance;
    }); // 列表

    (0, _api.retrieveReq)({
      orderNo: this.orderNo,
      pageNo: 1,
      pageSize: 1000
    }).then(function (res) {
      _this.successList = res.data.entities;
    });
  },
  methods: {
    cancelOrder: function cancelOrder(res) {
      var _this2 = this;

      (0, _api2.updatePayStatusCancelByOrderNo)(this.orderNo).then(function (res) {
        _this2.$notification["success"]({
          message: "Success",
          description: "订单已取消"
        });

        _this2.$router.push({
          path: "/provide/customerOrder"
        });
      }).catch(function (err) {
        _this2.$notification["error"]({
          message: "error",
          description: err.msg || "操作失败"
        });
      });
    },
    nextProcess: function nextProcess() {
      var _this3 = this;

      (0, _api2.updatePayStatusExamineByOrderNo)(this.orderNo).then(function (res) {
        _this3.$notification["success"]({
          message: "Success",
          description: "订单已提交"
        });

        _this3.$router.push({
          path: "/provide/customerOrder"
        });
      }).catch(function (err) {
        _this3.$notification["error"]({
          message: "error",
          description: err.msg || "操作失败"
        });
      });
    },
    goback: function goback() {
      var path = "/provide/customerOrder";
      this.$router.push({
        path: path
      });
    },
    goImport: function goImport() {
      this.$router.push({
        path: "/provide/customerOrder"
      });
    },
    goMoney: function goMoney() {
      this.$router.push({
        path: "/cost/customerRecharge"
      });
    }
  }
};
exports.default = _default;