"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _getters = _interopRequireDefault(require("./getters"));

var _app = _interopRequireDefault(require("./modules/app"));

var _navtab = _interopRequireDefault(require("./modules/navtab"));

var _settings = _interopRequireDefault(require("./modules/settings"));

var _project = _interopRequireDefault(require("./modules/project"));

var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));

_vue.default.use(_vuex.default);

var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    navtab: _navtab.default,
    settings: _settings.default,
    project: _project.default
  },
  getters: _getters.default,
  plugins: [(0, _vuexPersistedstate.default)({
    storage: window.localStorage
  })]
});
var _default = store;
exports.default = _default;